<template lang="">
  <div>
    <div class="row">
      <div class="col-12 filter-items">
        <div class="pull-right">
          <i class="icon-list"></i>
          <select @change="handleChageFilter" name="filter">
            <option value="all" :selected="filters.teacher_type == 'all'">
              {{ $t("lesson.filters.all") }}
            </option>
            <option value="expert" :selected="filters.teacher_type == 'expert'">
              {{ $t("lesson.filters.expert") }}
            </option>
            <option value="normal" :selected="filters.teacher_type == 'normal'">
              {{ $t("lesson.filters.normal") }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <h5 class="category-result pt-3">
          {{
            category_name == "all" ? $t("lesson.all_category") : category_name
          }}
        </h5>
      </div>
    </div>
    <div class="row">
      <div
        class="col-6 list"
        v-for="(item, index) in lessons.data"
        :key="index"
      >
        <router-link
          :to="{ name: 'lesson.detail', params: { lessonId: item.id } }"
          class="no-decoration"
        >
          <div class="ranking">
            <label v-if="item.rank">{{ item.rank }}</label>
          </div>
          <div class="cimg">
            <img
              :src="item.thumbnail"
              :alt="item.thumbnail"
              class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
              :title="item.title"
            />
          </div>
          <div class="description text-center">
            <h5 class="custom-text-overflow mt-2">{{ item.title }}</h5>
            <div class="introduction custom-text-overflow">
              <span class="expert" v-if="item.is_expert == 1"> 専門家 </span>
              {{ item.teacher_name }}
            </div>
            <div class="price text-left">
              {{ formatCurrency(item.currency_code, item.price) }}
            </div>
            <div class="reviewed-star rate text-left">
              <span class="star">
                <star-rating
                  :rating="Number(item.rated)"
                  :star-size="16"
                  :show-rating="false"
                  :read-only="true"
                />
              </span>
              <span
                >{{ item.rated }}
                {{ item.reviewed ? `(${item.reviewed})` : `` }}</span
              >
            </div>
          </div>
        </router-link>
      </div>
      <div
        class="col-12 text-center p-5"
        v-if="lessons.data.length === 0 && !loading"
      >
        <h3>{{ $t("lesson.lesson_no_available") }}</h3>
      </div>
      <div class="col-12">
        <Pagination
          :length="lessons.pagination.last"
          v-model="filters.page"
          @input="onPageChange"
          v-if="lessons.pagination.last > 1"
        />
      </div>
    </div>
    <MetaTags
      :title="`${category_name} ランキング｜相談｜ResMom相談online`"
      description="リセマム相談オンラインの人気ランキングです。｜リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。幼児教育、小学校受験、中学受験、高校受験、大学受験、海外進学、国際教育、グローバル教育、進路相談、進路コンサル、留学、子育て相談や悩みについて専門家や先輩保護者などに「すぐに」「気軽に」「どこでも」相談いただけます。"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ListLesson",
  data() {
    return {
      filters: {
        page: 1,
        tab: "lesson",
        teacher_type: "all",
        sorter: "new_arrival-DESC",
      },
      category: "all",
      child_category: 0,
      loading: 1,
      type: "rank",
      total: 0,
    };
  },
  props: ["category_name"],
  async created() {
    this.filters = this.$router.getQuery(this.filters);
    await this.getData();
  },
  computed: {
    ...mapGetters({
      lessons: "sellLesson/listSellLesson",
    }),
  },
  methods: {
    ...mapActions({
      getSellLessons: "sellLesson/getSellLessons",
    }),
    onPageChange(page) {
      this.filters = {
        ...this.filters,
        page,
      };
      this.$router.setQuery(this.filters);
    },
    handleSlugCategory() {
      let { slug } = this.$route.params;
      let _slug = slug;
      if (typeof slug == "string") {
        _slug = slug.split("/");
      }
      this.category = _slug[_slug.length - 1];
      this.child_category = _slug.length > 1 ? 1 : 0;
    },
    handleChageFilter(e) {
      this.filters = {
        ...this.filters,
        page: 1,
        teacher_type: e.target.value,
      };
      this.$router.setQuery(this.filters);
    },
    handleChageSorter(e) {
      this.filters = {
        ...this.filters,
        page: 1,
        sorter: e.target.value,
      };
      this.$router.setQuery(this.filters);
    },
    async getData() {
      this.handleSlugCategory();
      let params = {
        ...this.filters,
        category: this.category,
        child_category: this.child_category,
        type: this.type,
      };
      this.loading = 1;
      this.$isLoading(true);
      await this.getSellLessons(params);
      setTimeout(() => {
        this.$isLoading(false);
      }, 500);
      this.loading = 0;
      this.total = this.lessons.pagination.total;
    },
    handleClick(id) {
      this.$router.push({ name: "lesson.detail", params: { lessonId: id } });
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.filters = this.$router.getQuery(this.filters);
        this.getData();
      },
    },
  },
};
</script>
